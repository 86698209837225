import React, { Component } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input
} from 'reactstrap';
import {Redirect} from 'react-router';
import { withRouter } from 'react-router-dom'
import {Link} from 'react-router-dom';
import loadjs from 'loadjs';
import {loadUser, login} from "../../actions/auth/authActions";
import {clearErrors} from "../../actions/auth/errorActions";
import { connect } from 'react-redux';
import {getLocalStorageJsonDomain} from "../../utils/domainDispatcher";
import {LOCALSTORAGE_KEY} from "../../config/localStorage";
import {API_HOST} from "../../config/_entrypoint";
import PropTypes from 'prop-types';

class LoginPage extends Component
{

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      msg: null,
      portal: getLocalStorageJsonDomain(LOCALSTORAGE_KEY),
    };

  }

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  };

  componentDidMount() {
    loadjs('/js/main.js', () => {
    });
  }

  componentDidUpdate(prevProps) {
    const { error, isAuthenticated } = this.props;

    if (error !== prevProps.error) {
      // Check for register error
      if (error.id === 'LOGIN_FAIL') {
        this.setState({ msg: error.msg.message });
      } else {
        this.setState({ msg: null });
      }
    }
    if(prevProps.isAuthenticated !== this.props.isAuthenticated && this.props.isAuthenticated === true){
      this.props.history.go(-1)
    }
  }


  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const { username, password } = this.state;

    const user = {
      username,
      password
    };

    this.props.login(user);
  };

  render() {
    return (
      <div>

        <div className="sign section--bg" data-bg="img/section/section.jpg">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sign__content">
                  <Form onSubmit={this.onSubmit} className="sign__form">

                      <Link to={"/"} className="sign__logo">
                        <img alt=""
                           src={(this.state.portal.portalLogo ? API_HOST + this.state.portal.portalLogo.contentUrl : '#')}
                           className={"img-fluid"}/>
                      </Link>
                    <FormGroup className="sign__group">
                      <Input
                        type='phone'
                        name='username'
                        id='username'
                        placeholder='username'
                        className='mb-3 sign__input'
                        onChange={this.onChange}
                      />
                    </FormGroup>
                    <FormGroup className="sign__group">
                      <Input
                        type='password'
                        name='password'
                        id='password'
                        placeholder='Password'
                        className='mb-3 sign__input'
                        onChange={this.onChange}
                      />
                    </FormGroup>
                    <FormGroup className="sign__group">
                      {/*<input id="remember" name="remember" type="checkbox" checked="checked" />*/}
                      {/*  <label htmlFor="remember">Remember Me</label>*/}
                    </FormGroup>


                    {this.state.msg ? (
                      <span className='sign__text'>{this.state.msg}</span>
                    ) : null}
                    <Button className="sign__btn" style={{ marginTop: '2rem' }} block>
                      Login
                    </Button>
                    <span className="sign__text">Don't have an account? <a href="signup.html">Sign up!</a></span>

                    {/*<span className="sign__text"><a href="forgot.html">Forgot password?</a></span>*/}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.auth.isAuthenticated,
  error: state.auth.error
});

export default connect(
  mapStateToProps,
  { login, clearErrors, loadUser }
)(withRouter(LoginPage));
