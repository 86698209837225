import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { LOCALSTORAGE_KEY } from "../../config/localStorage";
import { getLocalStorageJsonDomain, unsetLocalStorageDomain } from '../../utils/domainDispatcher';
import { Header, Footer, Navigation, Section } from '../../components/Layout'

class LocalRefresh extends Component
{
    constructor(props) {
        super(props);
    }
    componentWillMount() {
    }

    render()
    {

                return <Redirect to='/' />


    }
}



export default LocalRefresh;

