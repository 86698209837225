import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { LOCALSTORAGE_KEY } from "../../config/localStorage";
import { getLocalStorageJsonDomain } from '../../utils/domainDispatcher';
import { Header, Footer, Navigation, Section } from '../../components/Layout'
import Loader from "../Layout/Loader";

class Conditions extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            portal: getLocalStorageJsonDomain(LOCALSTORAGE_KEY)
        }
    }

    render()
    {
        if (this.state.loading){
            return <Loader />
        } else {
            if (!this.state.portal){
                return <Redirect to='/' />
            }
        }
        return (
            <div className="welcome">
                {/*<Header portal={this.state.portal} />*/}
                <Navigation />

                <section className="section section--first section--bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="section__wrap">

                                    <h1 className="section__title">Conditions</h1>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer portal={this.state.portal} />
            </div>
        )
    }
}



export default Conditions;

