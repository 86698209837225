import React, { Component } from 'react';
class Header extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            portal: null,
            loading: true
        }
    }



    render() {

        return (
            <header className="welcome__top">
                <h2>{ this.props.portal.name }</h2>
            </header>
        )
    }
}

export default Header;