import React, { Component } from 'react';
import { Redirect } from 'react-router';
import PropTypes from "prop-types";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { reset, retrieve } from "../../actions/file/show";
import { LOCALSTORAGE_KEY } from "../../config/localStorage";
import { getLocalStorageJsonDomain, matchDomain } from '../../utils/domainDispatcher';
import { Header, Footer, Navigation, Section } from '../../components/Layout';
import { del } from "../../actions/file/delete";
import connect from "react-redux/es/connect/connect";
import Loader from "../Layout/Loader";
import { API_HOST } from "../../config/_entrypoint";
import LoginModal from "../auth/LoginModal";
import LoginPage from "../auth/LoginPage";
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import cx from "classnames";
import * as actionTypes from '../../actions/portalfile/index';
import store from "../../store";
import Video from '../portals/partial/video';
import { Link } from 'react-router-dom';
import { API } from "../../config/Api";
import { list } from "../../actions/portaltag/list";

class Content extends Component {
  constructor(props) {
    super(props);
    this.imgContainer = React.createRef();
    this.alsoLike = React.createRef();
    this.state = {
      loading: true,
      slug: this.props.match.params.slug,
      portal: getLocalStorageJsonDomain(LOCALSTORAGE_KEY),
      file: null,
      isAuthenticated: true,
      showAlsoLikeList: false,
      highlitedAlsoLike: false,
      portalFiles: [],
      totalCount: null,
      imgContainerWidth: null,
      imgContainerHeight: null,
      mouseOverUnit: false
    }
  }

  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    deleteError: PropTypes.string,
    deleteLoading: PropTypes.bool.isRequired,
    deleted: PropTypes.object,
    del: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    isLoading: PropTypes.bool
  };

  componentDidMount() {
    if (!this.state.portal) {
      matchDomain('api/portal', LOCALSTORAGE_KEY).then(element => {
        if (typeof element === 'object' && typeof element.name !== 'undefined') {
          this.setState({
            portal: element,
            loading: false,
            notFound: false
          }, () => {

          })
        }
      })
    }
    this.portalCategories()
    this.props.retrieve(decodeURIComponent('api/files/' + this.state.slug)).then(() => {
      this.setState({ file: this.props.retrieved, loading: false })
    }).catch(err => {
      console.log(err)
    });

  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  componentDidUpdate(prevState) {
    if (this.state.portalFiles.length > 0 && this.state.showAlsoLikeList && this.imgContainer.current.offsetWidth !== this.state.imgContainerWidth) {
      this.setCardCoverHeight();
    }
  }

  checkLogin = () => {
    if (!this.props.isLoading && !this.props.isAuthenticated) {
      this.props.history.push('/login')
    }
  }

  switchAlsoLike = () => {
    this.setState(prevState => ({
      showAlsoLikeList: !prevState.showAlsoLikeList
    }), () => {
      this.getCategoryFiles()

    })
  }

  getCategoryFiles = () => {
    this.props.unitsList(this.state.portal.id, this.state.file.category.id, 12, 0)
    store.subscribe(this.portalFileListRender);
  }

  listSwitch = () => {
    this.setState(state => ({
      highlitedAlsoLike: !state.highlitedAlsoLike
    }, () => {

    }))
  }

  setCardCoverHeight = () => {
    const imgContainerHeight = this.imgContainer.current.offsetWidth / 1.25
    this.setState({ imgContainerWidth: this.imgContainer.current.offsetWidth }, () => {
      this.setState({ imgContainerHeight: imgContainerHeight })
    }, () => {

    })
  }

  getPreview = (id) => {
    this.setState({ mouseOverUnit: true }, () => {
      this.setState({ contentId: id })
    })
  }

  discardPreview = () => {
    this.setState({ mouseOverUnit: false })
  }


  render() {
    console.log(this.state)
    if (this.state.loading || this.props.isLoading) {
      return <Loader active={"active"} />
    } else {

      if (!this.props.isLoading && !this.state.isAuthenticated) {
        return <LoginPage />
      }

      if (!this.state.file) {
        window.location.reload()
      }
    }

    let file = this.state.file
    let filename;
    if (file.file !== null) {
      filename = API_HOST + '/api/video/' + file.id + '/' + 'testValue'
    }
    let previewThumb = API_HOST + '/api/video/image/' + file.id
    let preview = API_HOST + '/api/video/image/' + file.id + '/full'
    const portalFileList = this.state.portalFiles.map((portalFile, index) =>
      <div key={index} className="col-12  col-sm-6 col-md-4 col-lg-3 category__card">
        <div className="card card--list">
          <div className="row">
            <div className="col-12">
              {this.state.contentId === portalFile.id && this.state.mouseOverUnit ?
                <div className="card__cover"
                  ref={this.imgContainer}
                  style={{ "height": this.state.imgContainerHeight + 'px' }}
                >
                  <a href={"/content/" + portalFile.id}>
                    <Video id={portalFile.id} src={API_HOST + '/api/video/preview/' + portalFile.id} type="video/webm"
                      style={{ "height": this.state.imgContainerHeight + 'px' }}
                    />
                  </a>
                </div>
                :
                <div className="card__cover"
                  ref={this.imgContainer}
                  style=
                  {{
                    background: "url(\"" + API_HOST + "/api/video/image/" + portalFile.id + '/full' + "\" ) center center / cover no-repeat",
                    "height": this.state.imgContainerHeight + 'px',
                    "width": 100 + "%"
                  }}

                  onMouseEnter={() => this.getPreview(portalFile.id)}
                  onMouseLeave={this.discardPreview}
                >
                </div>}
              <div className="card__content" style={{ padding: "15px 0" }}>
                <h3 className="card__title">
                  <Link to={"/content/" + portalFile.id} tabIndex="-1" aria-disabled="true">{portalFile.label}</Link>
                </h3>
                <span className="card__category">
                  <a href="#">Action</a>
                  <a href="#">Triler</a>
                </span>

                <div className="card__wrap">
                  <span className="card__rate"><i className="icon ion-ios-star"></i>8.4</span>

                  <ul className="card__list">
                    <li>HD</li>
                    <li>16+</li>
                  </ul>
                </div>
                <div className="card__description">
                  <p>{portalFile.description}</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
    return (
      <div className="welcome">
        <Navigation />
        <section className="section details">
          <div className="details__bg" data-bg="/img/home/home__bg.jpg"></div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="details__title">{file.label}</h1>
              </div>
              <div className="col-12 col-xl-12" onClick={this.checkLogin}>
                <video controls crossOrigin="true" playsInline
                  poster={preview} id="player">
                  <source src={filename}
                    type="video/mp4" />
                </video>
              </div>
              <div className="col-12 col-xl-12">
                <div className="card card--details">
                  <div className="row">
                    <div className="col-12">
                      <div className="card__content">
                        <div className="movie-additional">
                          <div className="movie-details--wrapper"
                          >
                            <h3 className="movie-details">
                              Movie Details
                          </h3>
                            <div className="movie-details-wrapper" >
                              <div className="card__wrap">
                                <span className="card__rate"><i className="icon ion-ios-star"></i>8.4</span>
                                <ul className="card__list">
                                  <li>HD</li>
                                  <li>16+</li>
                                </ul>
                              </div>
                              <ul className="card__meta">
                                <li className="movie-details--item"><span>Genre:</span> <a href="#">Action</a>
                                  <a href="#">Triler</a></li>
                                <li className="movie-details--item"><span>Release year:</span><span className="movie-details--item-value"> 2017</span></li>
                                <li className="movie-details--item"><span>Running time:</span><span className="movie-details--item-value"> 120 min</span></li>
                                <li className="movie-details--item"><span>Country:</span><span> <a href="#" className="movie-details--item-value">USA</a></span></li>
                                <li className="movie-details--item"><span>Description:</span><span className={cx('description-info', {
                                  'description-info-active': this.state.highlightedDetail
                                })}>It is a long established fact that a reader will be distracted by the readable content of a
                                                page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less
                                                normal distribution of letters, as opposed to using 'Content here, content here', making it
                                                look like readable English. Many desktop publishing packages and web page editors now use
                                                Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web
                              sites still in their infancy.</span></li>
                              </ul>
                            </div>
                          </div>
                          <div className="movie-additional-movie--wrapper" onClick={this.switchAlsoLike} ref={this.alsoLike}>
                            <CSSTransition
                              in={this.state.showAlsoLikeList && this.state.portalFiles.length > 0}
                              timeout={400}
                              classNames="dropdown-icon-add-movies-transition"
                              appear
                              onEnter={this.highlitedAlsoLike}
                              onExit={this.highlitedAlsoLike}
                            >
                              <i className="icon ion-md-arrow-dropright detailsDropdownIcon left" ></i>
                            </CSSTransition>
                            <h3 className="movie-details">
                              You may also like...
                            </h3>


                          </div>
                          <CSSTransition
                            in={this.state.showAlsoLikeList}
                            timeout={400}
                            classNames="list-alsoLike-transition"
                            unmountOnExit
                            appear
                            onEnter={this.highlitedAlsoLike}
                            onExit={this.highlitedAlsoLike}
                          >
                            <div className="row">
                              {portalFileList}
                            </div>
                          </CSSTransition>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>

        <Footer portal={this.state.portal} />
      </div>
    )
  }
  portalFileListRender = () => {
    let portalFiles = store.getState().portalFile.list2.retrieved.items;
    let totalCount = store.getState().portalFile.list2.retrieved.totalCount;
    if (portalFiles !== undefined) {
      this.setState({
        portalFiles: Object.values(portalFiles),
        loading: false,
      }, () => {
        if (totalCount !== undefined) {
          let items = Object.values(totalCount[0])[0]
          this.setState({ totalCount: Number(items) })
        }
      })
    }

  }

  portalCategories = () => {
    let portalCategories = store.getState().portalTag.retrieved
    if (!portalCategories) {
      let portalTagList = 'api/portal-tags/' + this.state.portal.id + '/category';
      store.dispatch(list(portalTagList));
    }
  }
}

const mapStateToProps = state => ({
  retrieved: state.file.show.retrieved,
  error: state.file.show.error,
  loading: state.file.show.loading,
  eventSource: state.file.show.eventSource,
  deleteError: state.file.del.error,
  deleteLoading: state.file.del.loading,
  deleted: state.file.del.deleted,
  isLoading: state.auth.auth.isLoading,
  isAuthenticated: state.auth.auth.isAuthenticated
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  del: file => dispatch(del(file)),
  reset: eventSource => dispatch(reset(eventSource)),
  unitsList: (id, name, limit, offset) => dispatch(actionTypes.units(id, name, limit, offset))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Content));


