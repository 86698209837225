import React, { Component } from "react"
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import store from "./store";
import Home from "./components/portals/home";
import Template from "./components/portals/template1";
import Categories from "./components/portals/categories";
import About from "./components/portals/about";
import Conditions from "./components/portals/conditions";
import Contact from "./components/portals/contact";
import Subscription from "./components/portals/subscription";
import LocalRefresh from "./components/portals/localRefresh";
import Content from "./components/portals/content";
import Category from "./components/portals/category";
import { createBrowserHistory } from "history";
import { syncHistoryWithStore } from 'react-router-redux';
import {loadUser} from "./actions/auth/authActions";
import Error from "./components/portals/error";
import LoginPage from "./components/auth/LoginPage";


const history = syncHistoryWithStore(createBrowserHistory(), store);

export default class App extends Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }


  render() {
    return(
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route path="/" component={Home} strict={true} exact={true}/>
            <Route path="/template" component={Template} strict={true} exact={true}/>
            <Route path="/categories" component={Categories} strict={true} exact={true}/>
            <Route path="/about" component={About} strict={true} exact={true}/>
            <Route path="/conditions" component={Conditions} strict={true} exact={true}/>
            <Route path="/contact" component={Contact} strict={true} exact={true}/>
            <Route path="/subscription" component={Subscription} strict={true} exact={true}/>
            <Route path="/local-refresh" component={LocalRefresh} strict={true} exact={true}/>
            <Route path="/content/:slug" component={Content} strict={true} exact={true}/>
            <Route path="/category/:id/:name" component={Category} strict={true} exact={true}/>
            <Route path="/not-found" component={Error}/>
            <Route path="/login" component={LoginPage}/>
            <Route component={Error}/>
          </Switch>
        </Router>
      </Provider>
    )
  }
}
