import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { list } from "../../actions/portaltag/list";
import { LOCALSTORAGE_KEY } from "../../config/localStorage";
import { getLocalStorageJsonDomain } from '../../utils/domainDispatcher';
import loadjs from 'loadjs';
import { API_HOST } from "../../config/_entrypoint";
import Logout from "../auth/Logout";
import LoginModal from "../auth/LoginModal";
import store from "../../store"



class Navigation extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      logo: null,
      portal: getLocalStorageJsonDomain(LOCALSTORAGE_KEY),
      portalTags: [],
      logged: false,
      showDropdown: false
    }
    this.portalTagListRender = this.portalTagListRender.bind(this)
    this.loadLogo = this.loadLogo.bind(this)
  }

  static propTypes = {
    auth: PropTypes.object.isRequired
  }

  componentWillMount() {
    this._isMounted = false;
  }


  componentWillUnmount() {
    this._isMounted = false;

  }

  componentDidMount() {
    loadjs('/js/main.js', () => {
    });
    store.subscribe(this.portalTagListRender);
    this.portalTagListRender()
  }


  loadLogo() {
    if (typeof this.state.portal.portalLogo.contentUrl !== 'undefined') {
      return (<img alt=""
        src={(API_HOST + this.state.portal.portalLogo.contentUrl)}
        className="img-fluid" />)
    } else {

      return (<h3>{this.state.portal.name}</h3>)

    }
  }

  toggleMenu = () => {
    this.setState({ showDropdown: !this.state.showDropdown })
  }

  render() {
    const categoriesArray = [];
    for (let index in this.state.portalTags[0]) {
      categoriesArray.push({
        id: index,
        config: this.state.portalTags[0][index]
      })
    }

    const { isAuthenticated, user } = this.props.auth;

    const portalTagList = this.state.portalTags.map(function (portalTag, index) {
      if (portalTag.group) {
        return <li key={index}>
          <Link to={"/category/" + portalTag.name} tabIndex="-1" aria-disabled="true">{portalTag.name} </Link>
        </li>
      }
    }

    );

    const authLinks = (
      <Logout />
    );
    const guestLinks = (
      <LoginModal ref={(modal) => this.modal} />
    );

    const userBlock = (
      <div className="dropdown header__lang">
        <a className="dropdown-toggle header__nav-link" href="#" role="button"
          id="dropdownMenuLang" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false"><i className={"ion-ios-contact"}></i></a>

        <ul className="dropdown-menu header__dropdown-menu"
          aria-labelledby="dropdownMenuLang">
          <li><a href="#">{user ? user.username : ''}</a></li>
        </ul>
      </div>
    )

    return (
      <header className="header">
        <div className="header__wrap">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="header__content">
                  <Link to="/" className="header__logo">
                    {this.loadLogo()}
                  </Link>
                  <ul className="header__nav">
                    <li className="header__nav-item">
                      <i className="icon ion-md-filing navIcons"></i>
                      <Link to="/categories" className="header__nav-link">all categories</Link>
                      <span className={!this.state.showDropdown ? 'dropDownIconWrapper' : 'dropDownIconWrapperRotated'} onClick={this.toggleMenu}>
                        <i className="icon ion-md-arrow-dropdown navIcons dropDownIcon" ></i>
                      </span>

                      <ul className={!this.state.showDropdown ? 'categoriesDropdownMenuHide' : 'categoriesDropdownMenu'}>
                        {categoriesArray.map((category, id) => (
                          <li key={id} className=' categoriesDropdownMenuItem'>
                            <Link to={"/category/" + category.config.id + '/' + category.config.name}
                              tabIndex="-1"
                              aria-disabled="true"
                              className=" header__nav-sublink">{category.config.name}</Link>
                              <i className="icon ion-md-arrow-dropright categoryIcon"></i>
                          </li>
                        ))}
                      </ul>
                    </li>


                    <li className="header__nav-item">
                      <i className="icon ion-md-mail-open navIcons"></i>
                      <Link to="/contact" className="header__nav-link">Contact</Link>
                    </li>

                    <li className="header__nav-item">
                      <i className="icon ion-md-information-circle navIcons"></i>
                      <Link to="/about" className="header__nav-link">About</Link>
                    </li>
                  </ul>

                  <div className="header__auth">
                    <button className="header__search-btn" type="button">
                      <i className="icon ion-ios-search"></i>
                    </button>


                    <div className="dropdown header__lang">
                      <a className="dropdown-toggle header__nav-link" href="#" role="button"
                        id="dropdownMenuLang" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">FR</a>

                      {/*<ul className="dropdown-menu header__dropdown-menu"*/}
                      {/*    aria-labelledby="dropdownMenuLang">*/}
                      {/*  <li><a href="#">English</a></li>*/}
                      {/*  <li><a href="#">Spanish</a></li>*/}
                      {/*  <li><a href="#">Russian</a></li>*/}
                      {/*</ul>*/}
                    </div>
                    {isAuthenticated ? userBlock : ''}

                    {(isAuthenticated ? authLinks : guestLinks)}
                  </div>
                  <button className="header__btn" type="button">
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form action="#" className="header__search">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="header__search-content">
                  <input type="text"
                    placeholder="Search for a movie, TV Series that you are looking for" />

                  <button type="button">search</button>
                </div>
              </div>
            </div>
          </div>
        </form>

      </header>
    )
  }


  portalTagListRender = () => {
    let portalTags = store.getState().portalTag.list.retrieved;
    if (portalTags !== null) {
      this.setState({
        portalTags: Object.values(portalTags),
        loading: false
      })
    }

  }
}
const mapStateToProps = state => ({
  auth: state.auth.auth
})

export default connect(mapStateToProps, null)(Navigation);

