import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { LOCALSTORAGE_KEY } from "../../config/localStorage";
import { getLocalStorageJsonDomain } from '../../utils/domainDispatcher';
import { Header, Footer, Navigation, Section } from '../../components/Layout'
import Loader from "../Layout/Loader";
import Breadcrumbs  from "./partial/breadcrumbs";
import { list } from '../../actions/portaltag/list';
import store from '../../store';

class Contact extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            portal: getLocalStorageJsonDomain(LOCALSTORAGE_KEY)
        }
    }
    async componentDidMount() {
        try {
            if (this.state.portal !== null) {
                let portalTagList = 'api/portal-tags/' + this.state.portal.id + '/category';
                await store.dispatch(list(portalTagList));
                store.subscribe(this.portalTagListRender);
            }
        } catch (err) {
            console.log(err)
        }
    }

    render()
    {
        if (this.state.loading){
            return <Loader />
        } else {
            if (!this.state.portal){
                return <Redirect to='/' />
            }
        }
        return (
            <div>
                {/*<Header portal={this.state.portal} />*/}
                <Navigation />
                <section className="section section--first section--bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="section__wrap">
                                    <h1 className="section__title">Contact</h1>
                                    <Breadcrumbs breadcrumbs={[{url:'/',name:'Home'},{name:'Contact'}]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="section__title"><b>{ this.state.portal.name }</b> – Contact us</h2>
                            </div>
                            <div className="col-12">
                                <p className="section__text">It is a long <b>established</b> fact that a reader will be
                                    distracted by the readable content of a page when looking at its layout. The point
                                    of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                    as opposed to using.</p>

                                <p className="section__text section__text--last-with-margin">'Content here, content
                                    here', making it look like <a href="#">readable</a> English. Many desktop publishing
                                    packages and web page editors now use Lorem Ipsum as their default model text, and a
                                    search for 'lorem ipsum' will uncover many web sites still in their infancy. Various
                                    versions have evolved over the years, sometimes by accident, sometimes on purpose
                                    (injected humour and the like).</p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer portal={this.state.portal} />
            </div>
        )
    }
}



export default Contact;

