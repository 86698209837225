import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadUser, login } from '../../actions/auth/authActions';
import { clearErrors } from '../../actions/auth/errorActions';
import { API_HOST } from "../../config/_entrypoint";
import { getLocalStorageJsonDomain } from "../../utils/domainDispatcher";
import { LOCALSTORAGE_KEY } from "../../config/localStorage";
import Cross from '../../UI/Cross/Cross';

class LoginModal extends Component {
  state = {
    modal: false,
    username: '',
    password: '',
    msg: null,
    portal: getLocalStorageJsonDomain(LOCALSTORAGE_KEY),
  };

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  };



  componentDidUpdate(prevProps) {
    const { error, isAuthenticated } = this.props;

    if (error !== prevProps.error) {
      // Check for register error
      if (error.id === 'LOGIN_FAIL') {
        this.setState({ msg: error.msg.message });
      } else {
        this.setState({ msg: null });
      }
    }

    // If authenticated, close modal
    if (this.state.modal) {
      if (isAuthenticated) {
        this.toggle();
      }
    }
  }

  toggle = () => {
    // Clear errors
    this.props.clearErrors();
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const { username, password } = this.state;

    const user = {
      username,
      password
    };

    this.props.login(user);
  };

  render() {
    return (
      <div>
        <a onClick={this.toggle} href='#' className={"header__sign-in"}>
          <i className="icon ion-ios-log-in"></i>
          <span>Log In</span>
        </a>

        <Modal className="sign__content" isOpen={this.state.modal} toggle={this.toggle} >
          <ModalBody>
            <Cross onClick={this.toggle}/>
            <Form onSubmit={this.onSubmit} className="sign__form">

              <a className="sign__logo">
                <img alt=""
                  src={(this.state.portal.portalLogo ? API_HOST + this.state.portal.portalLogo.contentUrl : '#')}
                  className={"img-fluid"} />
              </a>
              <FormGroup className="sign__group">
                <Input
                  type='phone'
                  name='username'
                  id='username'
                  placeholder='username'
                  className='mb-3 sign__input'
                  onChange={this.onChange}
                />
              </FormGroup>
              <FormGroup className="sign__group">
                <Input
                  type='password'
                  name='password'
                  id='password'
                  placeholder='Password'
                  className='mb-3 sign__input'
                  onChange={this.onChange}
                />
              </FormGroup>
              {this.state.msg ? (
                <span className='sign__text'>{this.state.msg}</span>
              ) : null}
              <Button className="sign__btn" style={{ marginTop: '2rem' }} block>
                Login
              </Button>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.auth.isAuthenticated,
  error: state.auth.error
});

export default connect(
  mapStateToProps,
  { login, clearErrors, loadUser }
)(LoginModal);
