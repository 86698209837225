import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (

            <footer className={"footer"}  >
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-sm-4 col-md-4 text-center">
                            <h6 className="footer__title">Resources</h6>
                            <ul className="footer__list">
                                <li><Link to='/about'>About Us</Link></li>
                                <li><a href="#">Unsubscribe</a></li>
                            </ul>
                        </div>

                        <div className="col-6 col-sm-4 col-md-4 text-center">
                            <h6 className="footer__title">Legal</h6>
                            <ul className="footer__list">
                                <li><a href="#">General conditions</a></li>
                                <li><a href="#">Privacy Policy</a></li>

                            </ul>
                        </div>

                        <div className="col-12 col-sm-4 col-md-4 text-center">
                            <h6 className="footer__title">Contact</h6>
                            <ul className="footer__list">
                                <li><a href="tel:+18002345678">+1 (800) 234-5678</a></li>
                                <li><a href="mailto:support@moviego.com">country@email.com</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={'container-fluid copyrights-container'}>
                    <div>
                        <div className="col-12">
                            <div className="footer__copyright">
                                <small>© {(new Date().getFullYear())} {this.props.portal.name}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;
