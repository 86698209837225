import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux'
import { LOCALSTORAGE_KEY } from "../../config/localStorage";
import { getLocalStorageJsonDomain } from '../../utils/domainDispatcher';
import { Footer, Navigation } from '../../components/Layout'
import { Link } from 'react-router-dom';
import Loader from '../Layout/Loader';
import { API_HOST } from "../../config/_entrypoint";
import store from "../../store";
import Breadcrumbs from "./partial/breadcrumbs";
import * as actionTypes from '../../actions/portalfile/index';
import Pagination from '../../UI/Pagination/Pagination';
import Video from '../portals/partial/video';
import { list } from '../../actions/portaltag/list'



class Category extends Component {
    constructor(props) {
        super(props);
        this.imgContainer = React.createRef()
        this.state = {
            loading: true,
            name: this.props.match.params.name,
            id: this.props.match.params.id,
            portal: getLocalStorageJsonDomain(LOCALSTORAGE_KEY),
            portalFiles: [],
            totalCount: [],
            currentPage: 1,
            limit: 12,
            offset: 0,
            mouseOverUnit: false,
            contentId: null,
            imgContainerHeight: 200,
            imgContainerWidth: 0
        }
        this.portalFileListRender = this.portalFileListRender.bind(this)
    }

    async componentDidMount() {
        this.props.unitsList(this.state.portal.id, this.props.match.params.id, this.state.limit, this.state.offset)
        store.subscribe(this.portalFileListRender);
        const { name } = this.props.match.params;
        try {
            if (this.state.portal !== null) {
                let portalTagList = 'api/portal-tags/' + this.state.portal.id + '/category';
                await store.dispatch(list(portalTagList));
                store.subscribe(this.portalTagListRender);
            }
        } catch (err) {
            
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({
                id: this.props.match.params.id
            }, () => {
                this.props.unitsList(this.state.portal.id, this.props.match.params.id, this.state.limit, this.state.offset)
            })
        }
        if (this.state.imgContainerWidth && this.imgContainer.current.offsetWidth !== this.state.imgContainerWidth) {
            this.setCardCoverHeight();
        }
        if(prevProps.match.params.name !== this.props.match.params.name){
            this.setState({ name: this.props.match.params.name })
        }

    }

    setCardCoverHeight = () => {
        const imgContainerHeight = this.imgContainer.current.offsetWidth / 1.25
        this.setState({ imgContainerWidth: this.imgContainer.current.offsetWidth }, () => {
            this.setState({ imgContainerHeight: imgContainerHeight })
        })

    }

    getPreview = (id) => {
        this.setState({ mouseOverUnit: true }, () => {
            this.setState({ contentId: id })
        })
    }

    discardPreview = () => {
        this.setState({ mouseOverUnit: false })
    }

    nextPage = (pageNumber) => {
        let offset = this.state.limit * (pageNumber - 1)
        this.props.unitsList(this.state.portal.id, this.state.id, this.state.limit, offset)
        this.setState({ currentPage: pageNumber })
    }

    render() {
        console.log(this.state)
        const numberPages = Math.ceil(this.state.totalCount / 12)
        if (this.state.loading) {
            return <Loader active={"active"} />
        } else {
            if (!this.state.portal) {
                return <Redirect to='/' />
            }
        }
        const portalFileList = this.state.portalFiles.map((portalFile, index) =>
            <div key={index} className="col-12  col-sm-6 col-md-4 col-lg-3 category__card">
                <div className="card card--list">
                    <div className="row">
                        <div className="col-12">
                            {this.state.contentId === portalFile.id && this.state.mouseOverUnit ?
                                <div className="card__cover"
                                    ref={this.imgContainer}
                                    style={{ "height": this.state.imgContainerHeight + 'px' }}
                                >
                                    <a href={"/content/" + portalFile.id}>
                                        <Video id={portalFile.id} src={API_HOST + '/api/video/preview/' + portalFile.id} type="video/webm"
                                            style={{ "height": this.state.imgContainerHeight + 'px' }}
                                        />
                                    </a>
                                </div>
                                :
                                <div className="card__cover"
                                    ref={this.imgContainer}
                                    style=
                                    {{
                                        background: "url(\"" + API_HOST + "/api/video/image/" + portalFile.id + '/full' + "\" ) center center / cover no-repeat",
                                        "height": this.state.imgContainerHeight + 'px',
                                    }}

                                    onMouseEnter={() => this.getPreview(portalFile.id)}
                                    onMouseLeave={this.discardPreview}
                                >
                                </div>}
                            <div className="card__content" style={{ padding: "15px 0" }}>
                                <h3 className="card__title">
                                    <Link to={"/content/" + portalFile.id} tabIndex="-1" aria-disabled="true">{portalFile.label}</Link>
                                </h3>
                                <span className="card__category">
                                    <a href="#">Action</a>
                                    <a href="#">Triler</a>
                                </span>

                                <div className="card__wrap">
                                    <span className="card__rate"><i className="icon ion-ios-star"></i>8.4</span>

                                    <ul className="card__list">
                                        <li>HD</li>
                                        <li>16+</li>
                                    </ul>
                                </div>
                                <div className="card__description">
                                    <p>{portalFile.description}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
        return (
            <div className="welcome">
                <Navigation />
                <section className="section section--first section--bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="section__wrap">
                                    <h1 className="section__title">{this.state.name}</h1>
                                    <Breadcrumbs breadcrumbs={[{ url: '/', name: 'Home' }, { url: '/categories', name: 'Categories' }]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="welcome__main">
                    <div className="main__content">
                        <div className="container">
                            <div className={"catalog"}>
                                <div className={"row"}>
                                    {portalFileList}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.state.totalCount && this.state.totalCount > 12 ?
                    <Pagination totalPages={numberPages} nextPage={this.nextPage} currentPage={this.state.currentPage} />
                    : null}
                <Footer portal={this.state.portal} />
            </div>
        )
    }

    portalFileListRender = () => {
        let portalFiles = store.getState().portalFile.list2.retrieved.items;
        let totalCount = store.getState().portalFile.list2.retrieved.totalCount;
        if (portalFiles !== undefined) {
            this.setState({
                portalFiles: Object.values(portalFiles),
                loading: false,
            }, () => {
                if (totalCount !== undefined) {
                    let items = Object.values(totalCount[0])[0]
                    this.setState({ totalCount: Number(items) })
                }
            })
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        unitsList: (id, name, limit, offset) => dispatch(actionTypes.units(id, name, limit, offset))
    }
}

export default connect(null, mapDispatchToProps)(Category);

