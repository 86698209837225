import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import { logout } from '../../actions/auth/authActions'
import PropTypes from 'prop-types';

export class Logout extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired
  };

  render() {
    return (

        <a onClick={this.props.logout} href = "#" className={"header__sign-in"}>
          <i className="icon ion-ios-log-in"></i>
          <span>Log Out</span>
        </a>

    )
  }
}

export default connect(null, { logout })(Logout);
