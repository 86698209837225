import { ENTRYPOINT } from '../config/entrypoint';
import {API} from "../config/Api";
const currentDomain = window.location.hostname;

function matchDomain(requestUrl, localStorageKey)
{

        return API.post(requestUrl, {url: currentDomain})
            .then( response => {
                return response.data
            })
            .then(json => {
                let match = null;

                if (json.url === currentDomain) {
                    match = json;
                    setLocalStorageDomain(localStorageKey, JSON.stringify(json));
                }

                return match;

            }).catch(e => {
                return e.message
            })

}

function setLocalStorageDomain(name, element)
{
    window.localStorage.setItem(name, element);
}

function unsetLocalStorageDomain(item)
{
    window.localStorage.removeItem(item);
}

function getLocalStorageJsonDomain(item)
{
    return JSON.parse(window.localStorage.getItem(item));
}

export { matchDomain, getLocalStorageJsonDomain, unsetLocalStorageDomain };
