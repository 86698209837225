import React from 'react';

import classes from './Pagination.module.css';
import { createPaginationArray } from './PaginationArray';

const pagination = (props) => {
    const { currentPage, setCurrentPage, totalPages } = props

    const paginationArray = createPaginationArray(currentPage, totalPages);
  
    return (
        <div className={classes.PaginationWrapper}>
            {currentPage > 1 ?
                    <span><a href="#" className={`PaginationLinkStyle`} onClick={() => props.nextPage(props.currentPage - 1)}>Prev</a></span> :
                    <span><a href="#" className={`PaginationLinkStyleDisabled`} >Prev</a></span>}
                {paginationArray &&
                    paginationArray.map((item, index) => {
                        if ('...' !== item && currentPage !== item) {
                            return (
                                <span key={`${item}-${index}`}>
                                    <a href="#" className={`PaginationLinkStyle`} onClick={() => props.nextPage(item)}><span>{item}</span></a>
                                </span>
                            )
                        } else {
                            return (
                                <span key={`${item}-${index}`}>
                                    <span className={currentPage === item ? 'PaginationLinkStyle active' : ''}>{item}</span>
                                </span>
                            )
                        }
                    })}
                {currentPage < totalPages ?
                    <span><a href="#" className={`PaginationLinkStyle`} onClick={() => props.nextPage(props.currentPage + 1)}>Next</a></span> :
                    <span><a href="#" className={`PaginationLinkStyleDisabled`} >Next</a></span>}
        </div>
    )
};

export default pagination;
