import React, { Component, Fragment } from 'react'
import { API } from "../../../config/Api";
import { API_HOST } from "../../../config/_entrypoint";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Arrow from '../../../UI/Arrow/Arrow';
import Video from '../partial/video';

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.carouseImgContainer = React.createRef();
    this.state = {
      carouselItems: [],
      loading: null,
      cnt: 0,
      mouseOverUnit: false,
      contentId: null,
      imgContainerHeight: 0,
      imgContainerWidth: 0
    }

    this.imageLoaded = this.imageLoaded.bind(this);
  }

  componentWillMount() {
    API.get(this.props.url).then(result => {
      this.setState({
        carouselItems: result.data,
        loading: true
      })
    })
  }

  componentDidUpdate() {
//    if( this.carouseImgContainer.current.offsetWidth !== this.state.imgContainerWidth){
//      this.setCardCoverHeight();
//    }
  }

  imageLoaded() {
    if (this.state.loading === false) {
      return
    }
    if (this.state.cnt >= this.state.carouselItems.length) {
      this.setState({ loading: false });
    } else {
      this.setState({
        cnt: this.state.cnt + 1
      });
    }
  }

  getPreview = (id) => {
    this.setState({ contentId: id }, () => {
      this.setState({ mouseOverUnit: true })
    })
  }

  discardPreview = () => {
    this.setState({ mouseOverUnit: false })
  }

  setCardCoverHeight = () => {
    const imgContainerHeight = this.carouseImgContainer.current.offsetWidth / 1.25
    this.setState({ imgContainerWidth: this.carouseImgContainer.current.offsetWidth }, () => {
      this.setState({ imgContainerHeight: imgContainerHeight })
    })
  }


  render() {
    if (this.state.carouselItems.length === 0) {
      return null;
    }

    const settings = {
      autoPlaySpeed: 600,
      slidesToShow: 4,
      slidesToScroll: 4,
      infinite: false,
      arrows: true,
      lazyLoad: 'progressive',
      prevArrow: <Arrow to="prev" />,
      nextArrow: <Arrow to="next" />,
      responsive: [
        {
          breakpoint: 0,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          },
        }
      ]
    };

    return (
      <div >
        <div className="home__bg">
          <div className="item home__cover" data-bg="img/home/home__bg.jpg"></div>
          <div className="item home__cover" data-bg="img/home/home__bg.jpg"></div>
          <div className="item home__cover" data-bg="img/home/home__bg.jpg"></div>
          <div className="item home__cover" data-bg="img/home/home__bg.jpg"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="home__title"><b>NEW ITEMS</b> OF THIS SEASON</h1>
              {(window.innerWidth < 768) ?
                <Fragment>
                  <button className="home__nav home__NewItemsNav--prev" type="button" onClick={() => this.slider.slickPrev()}>
                    <i className="icon ion-ios-arrow-round-back"></i>
                  </button>
                  <button className="home__nav home__NewItemsNav--next" type="button" onClick={() => this.slider.slickNext()}>
                    <i className="icon ion-ios-arrow-round-forward"></i>
                  </button>
                </Fragment>
                : null}
            </div>

            <div className="col-12">
              <Slider className="home__carousel" {...settings} ref={slider => (this.slider = slider)}>
                {
                  this.state.carouselItems.map((file, i) =>
                    <div className="item" key={i}>
                      <div className="card card--big">
                        {
                          this.state.contentId === file.id && this.state.mouseOverUnit ?
                            <div className="card__cover" 
                              style={{ "height": this.state.imgContainerHeight + 'px' }}
                              ref={this.carouseImgContainer}
                            >
                              <a href={"/content/" + file.id}>
                                <Video id={file.id} src={API_HOST + '/api/video/preview/' + file.id} type="video/webm"
                                  style={{ "height": this.state.imgContainerHeight + 'px' }}
                                />
                              </a>
                            </div> :
                            <div className="card__cover"
                              onMouseEnter={() => this.getPreview(file.id)}
                              onMouseLeave={this.discardPreview}
                              ref={this.carouseImgContainer}
                              style={{ "height": this.state.imgContainerHeight + 'px' }}>
                                
                              <img
                                src={API_HOST + "/api/video/image/" + file.id + '/full'}
                                alt=""
                                style={{ "height": this.state.imgContainerHeight + 'px' }}
                              />
                            </div>
                        }
                        <div className="card__content">
                          <h3 className="card__title"><a href={"/content/" + file.id}>{file.label}</a>
                          </h3>
                          <span className="card__category">
                            <a href="#">Action</a>
                            <a href="#">Triler</a>
                          </span>
                          <span className="card__rate"><i
                            className="icon ion-ios-star"></i>8.4</span>
                        </div>
                      </div>

                    </div>
                  )
                }
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }

}



export default Carousel
