import React, { Component } from 'react';
import Home from "./home";

class Template extends Component {
    render() {
        return (

            <div>
                <header className="header">
                    <div className="header__wrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="header__content">

                                        <a href="index.html" className="header__logo">
                                            <img src="img/logo.svg" alt="" />
                                        </a>

                                        <ul className="header__nav">

                                            <li className="header__nav-item">
                                                <a className="dropdown-toggle header__nav-link" href="#" role="button"
                                                   id="dropdownMenuHome" data-toggle="dropdown" aria-haspopup="true"
                                                   aria-expanded="false">Home</a>

                                                <ul className="dropdown-menu header__dropdown-menu"
                                                    aria-labelledby="dropdownMenuHome">
                                                    <li><a href="index.html">Home slideshow bg</a></li>
                                                    <li><a href="index2.html">Home static bg</a></li>
                                                </ul>
                                            </li>

                                            <li className="header__nav-item">
                                                <a className="dropdown-toggle header__nav-link" href="#" role="button"
                                                   id="dropdownMenuCatalog" data-toggle="dropdown" aria-haspopup="true"
                                                   aria-expanded="false">Catalog</a>

                                                <ul className="dropdown-menu header__dropdown-menu"
                                                    aria-labelledby="dropdownMenuCatalog">
                                                    <li><a href="catalog1.html">Catalog Grid</a></li>
                                                    <li><a href="catalog2.html">Catalog List</a></li>
                                                    <li><a href="details1.html">Details Movie</a></li>
                                                    <li><a href="details2.html">Details TV Series</a></li>
                                                </ul>
                                            </li>


                                            <li className="header__nav-item">
                                                <a href="pricing.html" className="header__nav-link">Pricing Plan</a>
                                            </li>

                                            <li className="header__nav-item">
                                                <a href="faq.html" className="header__nav-link">Help</a>
                                            </li>


                                            <li className="dropdown header__nav-item">
                                                <a className="dropdown-toggle header__nav-link header__nav-link--more"
                                                   href="#" role="button" id="dropdownMenuMore" data-toggle="dropdown"
                                                   aria-haspopup="true" aria-expanded="false"><i
                                                    className="icon ion-ios-more"></i></a>

                                                <ul className="dropdown-menu header__dropdown-menu"
                                                    aria-labelledby="dropdownMenuMore">
                                                    <li><a href="about.html">About</a></li>
                                                    <li><a href="profile.html">Profile</a></li>
                                                    <li><a href="signin.html">Sign In</a></li>
                                                    <li><a href="signup.html">Sign Up</a></li>
                                                    <li><a href="404.html">404 Page</a></li>
                                                </ul>
                                            </li>

                                        </ul>

                                        <div className="header__auth">
                                            <button className="header__search-btn" type="button">
                                                <i className="icon ion-ios-search"></i>
                                            </button>


                                            <div className="dropdown header__lang">
                                                <a className="dropdown-toggle header__nav-link" href="#" role="button"
                                                   id="dropdownMenuLang" data-toggle="dropdown" aria-haspopup="true"
                                                   aria-expanded="false">EN</a>

                                                <ul className="dropdown-menu header__dropdown-menu"
                                                    aria-labelledby="dropdownMenuLang">
                                                    <li><a href="#">English</a></li>
                                                    <li><a href="#">Spanish</a></li>
                                                    <li><a href="#">Russian</a></li>
                                                </ul>
                                            </div>

                                            <a href="signin.html" className="header__sign-in">
                                                <i className="icon ion-ios-log-in"></i>
                                                <span>sign in</span>
                                            </a>
                                        </div>

                                        <button className="header__btn" type="button">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <form action="#" className="header__search">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="header__search-content">
                                        <input type="text"
                                               placeholder="Search for a movie, TV Series that you are looking for" />

                                            <button type="button">search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </header>

                <section className="home">

                    <div className="owl-carousel home__bg">
                        <div className="item home__cover" data-bg="img/home/home__bg.jpg"></div>
                        <div className="item home__cover" data-bg="img/home/home__bg.jpg"></div>
                        <div className="item home__cover" data-bg="img/home/home__bg.jpg"></div>
                        <div className="item home__cover" data-bg="img/home/home__bg.jpg"></div>
                    </div>


                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="home__title"><b>NEW ITEMS</b> OF THIS SEASON</h1>

                                <button className="home__nav home__nav--prev" type="button">
                                    <i className="icon ion-ios-arrow-round-back"></i>
                                </button>
                                <button className="home__nav home__nav--next" type="button">
                                    <i className="icon ion-ios-arrow-round-forward"></i>
                                </button>
                            </div>

                            <div className="col-12">
                                <div className="owl-carousel home__carousel">
                                    <div className="item">

                                        <div className="card card--big">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">I Dream in Another Language</a></h3>
                                                <span className="card__category">
										<a href="#">Action</a>
										<a href="#">Triler</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>8.4</span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="item">

                                        <div className="card card--big">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Benched</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.1</span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="item">

                                        <div className="card card--big">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Whitney</a></h3>
                                                <span className="card__category">
										<a href="#">Romance</a>
										<a href="#">Drama</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>6.3</span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="item">

                                        <div className="card card--big">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Blindspotting</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
										<a href="#">Drama</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.9</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section className="content">
                    <div className="content__head">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">

                                    <h2 className="content__title">New items</h2>



                                    <ul className="nav nav-tabs content__tabs" id="content__tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#tab-1" role="tab"
                                               aria-controls="tab-1" aria-selected="true">NEW RELEASES</a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#tab-2" role="tab"
                                               aria-controls="tab-2" aria-selected="false">MOVIES</a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#tab-3" role="tab"
                                               aria-controls="tab-3" aria-selected="false">TV SERIES</a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#tab-4" role="tab"
                                               aria-controls="tab-4" aria-selected="false">CARTOONS</a>
                                        </li>
                                    </ul>



                                    <div className="content__mobile-tabs" id="content__mobile-tabs">
                                        <div className="content__mobile-tabs-btn dropdown-toggle" role="navigation"
                                             id="mobile-tabs" data-toggle="dropdown" aria-haspopup="true"
                                             aria-expanded="false">
                                            <input type="button" value="New items" />
                                                <span></span>
                                        </div>

                                        <div className="content__mobile-tabs-menu dropdown-menu"
                                             aria-labelledby="mobile-tabs">
                                            <ul className="nav nav-tabs" role="tablist">
                                                <li className="nav-item"><a className="nav-link active" id="1-tab"
                                                                            data-toggle="tab" href="#tab-1" role="tab"
                                                                            aria-controls="tab-1" aria-selected="true">NEW
                                                    RELEASES</a></li>

                                                <li className="nav-item"><a className="nav-link" id="2-tab"
                                                                            data-toggle="tab" href="#tab-2" role="tab"
                                                                            aria-controls="tab-2"
                                                                            aria-selected="false">MOVIES</a></li>

                                                <li className="nav-item"><a className="nav-link" id="3-tab"
                                                                            data-toggle="tab" href="#tab-3" role="tab"
                                                                            aria-controls="tab-3" aria-selected="false">TV
                                                    SERIES</a></li>

                                                <li className="nav-item"><a className="nav-link" id="4-tab"
                                                                            data-toggle="tab" href="#tab-4" role="tab"
                                                                            aria-controls="tab-4"
                                                                            aria-selected="false">CARTOONS</a></li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">

                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="1-tab">
                                <div className="row">

                                    <div className="col-6 col-sm-12 col-lg-6">
                                        <div className="card card--list">
                                            <div className="row">
                                                <div className="col-12 col-sm-4">
                                                    <div className="card__cover">
                                                        <img src="img/covers/cover.jpg" alt="" />
                                                            <a href="#" className="card__play">
                                                                <i className="icon ion-ios-play"></i>
                                                            </a>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-8">
                                                    <div className="card__content">
                                                        <h3 className="card__title"><a href="#">I Dream in Another
                                                            Language</a></h3>
                                                        <span className="card__category">
												<a href="#">Action</a>
												<a href="#">Triler</a>
											</span>

                                                        <div className="card__wrap">
                                                        <span className="card__rate"><i
                                                            className="icon ion-ios-star"></i>8.4</span>

                                                            <ul className="card__list">
                                                                <li>HD</li>
                                                                <li>16+</li>
                                                            </ul>
                                                        </div>

                                                        <div className="card__description">
                                                            <p>It is a long established fact that a reader will be
                                                                distracted by the readable content of a page when looking at
                                                                its layout. The point of using Lorem Ipsum is that it has a
                                                                more-or-less normal distribution of letters, as opposed to
                                                                using 'Content here, content here', making it look like
                                                                readable English.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-12 col-lg-6">
                                        <div className="card card--list">
                                            <div className="row">
                                                <div className="col-12 col-sm-4">
                                                    <div className="card__cover">
                                                        <img src="img/covers/cover.jpg" alt="" />
                                                            <a href="#" className="card__play">
                                                                <i className="icon ion-ios-play"></i>
                                                            </a>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-8">
                                                    <div className="card__content">
                                                        <h3 className="card__title"><a href="#">Benched</a></h3>
                                                        <span className="card__category">
												<a href="#">Comedy</a>
											</span>

                                                        <div className="card__wrap">
                                                        <span className="card__rate"><i
                                                            className="icon ion-ios-star"></i>7.1</span>

                                                            <ul className="card__list">
                                                                <li>HD</li>
                                                                <li>16+</li>
                                                            </ul>
                                                        </div>

                                                        <div className="card__description">
                                                            <p>Lorem Ipsum is simply dummy text of the printing and
                                                                typesetting industry. Lorem Ipsum has been the industry's
                                                                standard dummy text ever since the 1500s, when an unknown
                                                                printer took a galley of type and scrambled it to make a
                                                                type specimen book.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-12 col-lg-6">
                                        <div className="card card--list">
                                            <div className="row">
                                                <div className="col-12 col-sm-4">
                                                    <div className="card__cover">
                                                        <img src="img/covers/cover.jpg" alt="" />
                                                            <a href="#" className="card__play">
                                                                <i className="icon ion-ios-play"></i>
                                                            </a>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-8">
                                                    <div className="card__content">
                                                        <h3 className="card__title"><a href="#">Whitney</a></h3>
                                                        <span className="card__category">
												<a href="#">Romance</a>
												<a href="#">Drama</a>
												<a href="#">Music</a>
											</span>

                                                        <div className="card__wrap">
                                                        <span className="card__rate"><i
                                                            className="icon ion-ios-star"></i>6.3</span>

                                                            <ul className="card__list">
                                                                <li>HD</li>
                                                                <li>16+</li>
                                                            </ul>
                                                        </div>

                                                        <div className="card__description">
                                                            <p>It has survived not only five centuries, but also the leap
                                                                into electronic typesetting, remaining essentially
                                                                unchanged. It was popularised in the 1960s with the release
                                                                of Letraset sheets containing Lorem Ipsum passages, and more
                                                                recently with desktop publishing software like Aldus
                                                                PageMaker including versions of Lorem Ipsum.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-12 col-lg-6">
                                        <div className="card card--list">
                                            <div className="row">
                                                <div className="col-12 col-sm-4">
                                                    <div className="card__cover">
                                                        <img src="img/covers/cover.jpg" alt="" />
                                                            <a href="#" className="card__play">
                                                                <i className="icon ion-ios-play"></i>
                                                            </a>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-8">
                                                    <div className="card__content">
                                                        <h3 className="card__title"><a href="#">Blindspotting</a></h3>
                                                        <span className="card__category">
												<a href="#">Comedy</a>
												<a href="#">Drama</a>
											</span>

                                                        <div className="card__wrap">
                                                        <span className="card__rate"><i
                                                            className="icon ion-ios-star"></i>7.9</span>

                                                            <ul className="card__list">
                                                                <li>HD</li>
                                                                <li>16+</li>
                                                            </ul>
                                                        </div>

                                                        <div className="card__description">
                                                            <p>Many desktop publishing packages and web page editors now use
                                                                Lorem Ipsum as their default model text, and a search for
                                                                'lorem ipsum' will uncover many web sites still in their
                                                                infancy. Various versions have evolved over the years,
                                                                sometimes by accident, sometimes on purpose (injected humour
                                                                and the like).</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-12 col-lg-6">
                                        <div className="card card--list">
                                            <div className="row">
                                                <div className="col-12 col-sm-4">
                                                    <div className="card__cover">
                                                        <img src="img/covers/cover.jpg" alt="" />
                                                            <a href="#" className="card__play">
                                                                <i className="icon ion-ios-play"></i>
                                                            </a>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-8">
                                                    <div className="card__content">
                                                        <h3 className="card__title"><a href="#">I Dream in Another
                                                            Language</a></h3>
                                                        <span className="card__category">
												<a href="#">Action</a>
												<a href="#">Triler</a>
											</span>

                                                        <div className="card__wrap">
                                                        <span className="card__rate"><i
                                                            className="icon ion-ios-star"></i>8.4</span>

                                                            <ul className="card__list">
                                                                <li>HD</li>
                                                                <li>16+</li>
                                                            </ul>
                                                        </div>

                                                        <div className="card__description">
                                                            <p>There are many variations of passages of Lorem Ipsum
                                                                available, but the majority have suffered alteration in some
                                                                form, by injected humour, or randomised words which don't
                                                                look even slightly believable. If you are going to use a
                                                                passage of Lorem Ipsum, you need to be sure there isn't
                                                                anything embarrassing hidden in the middle of text.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-12 col-lg-6">
                                        <div className="card card--list">
                                            <div className="row">
                                                <div className="col-12 col-sm-4">
                                                    <div className="card__cover">
                                                        <img src="img/covers/cover.jpg" alt="" />
                                                            <a href="#" className="card__play">
                                                                <i className="icon ion-ios-play"></i>
                                                            </a>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-8">
                                                    <div className="card__content">
                                                        <h3 className="card__title"><a href="#">Benched</a></h3>
                                                        <span className="card__category">
												<a href="#">Comedy</a>
											</span>

                                                        <div className="card__wrap">
                                                        <span className="card__rate"><i
                                                            className="icon ion-ios-star"></i>7.1</span>

                                                            <ul className="card__list">
                                                                <li>HD</li>
                                                                <li>16+</li>
                                                            </ul>
                                                        </div>

                                                        <div className="card__description">
                                                            <p>All the Lorem Ipsum generators on the Internet tend to repeat
                                                                predefined chunks as necessary, making this the first true
                                                                generator on the Internet. It uses a dictionary of over 200
                                                                Latin words, combined with a handful of model sentence
                                                                structures, to generate Lorem Ipsum which looks reasonable.
                                                                The generated Lorem Ipsum is therefore always free from
                                                                repetition, injected humour, or non-characteristic words
                                                                etc.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="2-tab">
                                <div className="row">

                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">I Dream in Another Language</a></h3>
                                                <span className="card__category">
										<a href="#">Action</a>
										<a href="#">Triler</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>8.4</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Benched</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.1</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Whitney</a></h3>
                                                <span className="card__category">
										<a href="#">Romance</a>
										<a href="#">Drama</a>
										<a href="#">Music</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>6.3</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Blindspotting</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
										<a href="#">Drama</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.9</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">I Dream in Another Language</a></h3>
                                                <span className="card__category">
										<a href="#">Action</a>
										<a href="#">Triler</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>8.4</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Benched</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.1</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Whitney</a></h3>
                                                <span className="card__category">
										<a href="#">Romance</a>
										<a href="#">Drama</a>
										<a href="#">Music</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>6.3</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Blindspotting</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
										<a href="#">Drama</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.9</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">I Dream in Another Language</a></h3>
                                                <span className="card__category">
										<a href="#">Action</a>
										<a href="#">Triler</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>8.4</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Benched</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.1</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Whitney</a></h3>
                                                <span className="card__category">
										<a href="#">Romance</a>
										<a href="#">Drama</a>
										<a href="#">Music</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>6.3</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Blindspotting</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
										<a href="#">Drama</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.9</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="tab-pane fade" id="tab-3" role="tabpanel" aria-labelledby="3-tab">
                                <div className="row">

                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">I Dream in Another Language</a></h3>
                                                <span className="card__category">
										<a href="#">Action</a>
										<a href="#">Triler</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>8.4</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Benched</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.1</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Whitney</a></h3>
                                                <span className="card__category">
										<a href="#">Romance</a>
										<a href="#">Drama</a>
										<a href="#">Music</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>6.3</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Blindspotting</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
										<a href="#">Drama</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.9</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">I Dream in Another Language</a></h3>
                                                <span className="card__category">
										<a href="#">Action</a>
										<a href="#">Triler</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>8.4</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Benched</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.1</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Whitney</a></h3>
                                                <span className="card__category">
										<a href="#">Romance</a>
										<a href="#">Drama</a>
										<a href="#">Music</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>6.3</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Blindspotting</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
										<a href="#">Drama</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.9</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">I Dream in Another Language</a></h3>
                                                <span className="card__category">
										<a href="#">Action</a>
										<a href="#">Triler</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>8.4</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Benched</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.1</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Whitney</a></h3>
                                                <span className="card__category">
										<a href="#">Romance</a>
										<a href="#">Drama</a>
										<a href="#">Music</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>6.3</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Blindspotting</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
										<a href="#">Drama</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.9</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="tab-pane fade" id="tab-4" role="tabpanel" aria-labelledby="4-tab">
                                <div className="row">

                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">I Dream in Another Language</a></h3>
                                                <span className="card__category">
										<a href="#">Action</a>
										<a href="#">Triler</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>8.4</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Benched</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.1</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Whitney</a></h3>
                                                <span className="card__category">
										<a href="#">Romance</a>
										<a href="#">Drama</a>
										<a href="#">Music</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>6.3</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Blindspotting</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
										<a href="#">Drama</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.9</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">I Dream in Another Language</a></h3>
                                                <span className="card__category">
										<a href="#">Action</a>
										<a href="#">Triler</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>8.4</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Benched</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.1</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Whitney</a></h3>
                                                <span className="card__category">
										<a href="#">Romance</a>
										<a href="#">Drama</a>
										<a href="#">Music</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>6.3</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Blindspotting</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
										<a href="#">Drama</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.9</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">I Dream in Another Language</a></h3>
                                                <span className="card__category">
										<a href="#">Action</a>
										<a href="#">Triler</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>8.4</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Benched</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.1</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Whitney</a></h3>
                                                <span className="card__category">
										<a href="#">Romance</a>
										<a href="#">Drama</a>
										<a href="#">Music</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>6.3</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                        <div className="card">
                                            <div className="card__cover">
                                                <img src="img/covers/cover.jpg" alt="" />
                                                    <a href="#" className="card__play">
                                                        <i className="icon ion-ios-play"></i>
                                                    </a>
                                            </div>
                                            <div className="card__content">
                                                <h3 className="card__title"><a href="#">Blindspotting</a></h3>
                                                <span className="card__category">
										<a href="#">Comedy</a>
										<a href="#">Drama</a>
									</span>
                                                <span className="card__rate"><i className="icon ion-ios-star"></i>7.9</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="section section--bg" data-bg="img/section/section.jpg">
                    <div className="container">
                        <div className="row">

                            <div className="col-12">
                                <h2 className="section__title">Expected premiere</h2>
                            </div>

                            <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                <div className="card">
                                    <div className="card__cover">
                                        <img src="img/covers/cover.jpg" alt="" />
                                            <a href="#" className="card__play">
                                                <i className="icon ion-ios-play"></i>
                                            </a>
                                    </div>
                                    <div className="card__content">
                                        <h3 className="card__title"><a href="#">I Dream in Another Language</a></h3>
                                        <span className="card__category">
								<a href="#">Action</a>
								<a href="#">Triler</a>
							</span>
                                        <span className="card__rate"><i className="icon ion-ios-star"></i>8.4</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                <div className="card">
                                    <div className="card__cover">
                                        <img src="img/covers/cover.jpg" alt="" />
                                            <a href="#" className="card__play">
                                                <i className="icon ion-ios-play"></i>
                                            </a>
                                    </div>
                                    <div className="card__content">
                                        <h3 className="card__title"><a href="#">Benched</a></h3>
                                        <span className="card__category">
								<a href="#">Comedy</a>
							</span>
                                        <span className="card__rate"><i className="icon ion-ios-star"></i>7.1</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                <div className="card">
                                    <div className="card__cover">
                                        <img src="img/covers/cover.jpg" alt="" />
                                            <a href="#" className="card__play">
                                                <i className="icon ion-ios-play"></i>
                                            </a>
                                    </div>
                                    <div className="card__content">
                                        <h3 className="card__title"><a href="#">Whitney</a></h3>
                                        <span className="card__category">
								<a href="#">Romance</a>
								<a href="#">Drama</a>
								<a href="#">Music</a>
							</span>
                                        <span className="card__rate"><i className="icon ion-ios-star"></i>6.3</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                <div className="card">
                                    <div className="card__cover">
                                        <img src="img/covers/cover.jpg" alt="" />
                                            <a href="#" className="card__play">
                                                <i className="icon ion-ios-play"></i>
                                            </a>
                                    </div>
                                    <div className="card__content">
                                        <h3 className="card__title"><a href="#">Blindspotting</a></h3>
                                        <span className="card__category">
								<a href="#">Comedy</a>
								<a href="#">Drama</a>
							</span>
                                        <span className="card__rate"><i className="icon ion-ios-star"></i>7.9</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                <div className="card">
                                    <div className="card__cover">
                                        <img src="img/covers/cover.jpg" alt="" />
                                            <a href="#" className="card__play">
                                                <i className="icon ion-ios-play"></i>
                                            </a>
                                    </div>
                                    <div className="card__content">
                                        <h3 className="card__title"><a href="#">I Dream in Another Language</a></h3>
                                        <span className="card__category">
								<a href="#">Action</a>
								<a href="#">Triler</a>
							</span>
                                        <span className="card__rate"><i className="icon ion-ios-star"></i>8.4</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
                                <div className="card">
                                    <div className="card__cover">
                                        <img src="img/covers/cover.jpg" alt="" />
                                            <a href="#" className="card__play">
                                                <i className="icon ion-ios-play"></i>
                                            </a>
                                    </div>
                                    <div className="card__content">
                                        <h3 className="card__title"><a href="#">Benched</a></h3>
                                        <span className="card__category">
								<a href="#">Comedy</a>
							</span>
                                        <span className="card__rate"><i className="icon ion-ios-star"></i>7.1</span>
                                    </div>
                                </div>
                            </div>



                            <div className="col-12">
                                <a href="#" className="section__btn">Show more</a>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="row">

                            <div className="col-12">
                                <h2 className="section__title section__title--no-margin">Our Partners</h2>
                            </div>

                            <div className="col-12">
                                <p className="section__text section__text--last-with-margin">It is a
                                    long <b>established</b> fact that a reader will be distracted by the readable content of
                                    a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                                    more-or-less normal distribution of letters, as opposed to using.</p>
                            </div>

                            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                                <a href="#" className="partner">
                                    <img src="img/partners/partner.jpg" alt="" className="partner__img" />
                                </a>
                            </div>

                            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                                <a href="#" className="partner">
                                    <img src="img/partners/partner.jpg" alt="" className="partner__img" />
                                </a>
                            </div>

                            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                                <a href="#" className="partner">
                                    <img src="img/partners/partner.jpg" alt="" className="partner__img" />
                                </a>
                            </div>

                            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                                <a href="#" className="partner">
                                    <img src="img/partners/partner.jpg" alt="" className="partner__img" />
                                </a>
                            </div>

                            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                                <a href="#" className="partner">
                                    <img src="img/partners/partner.jpg" alt="" className="partner__img" />
                                </a>
                            </div>

                            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                                <a href="#" className="partner">
                                    <img src="img/partners/partner.jpg" alt="" className="partner__img" />
                                </a>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
    )

    }

    }

    export default Template;
