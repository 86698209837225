import {API} from "../../config/Api";
import { returnErrors } from './errorActions'
import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL
} from "./types"

export const loadUser = () => (dispatch, getState) => {
  dispatch({type: USER_LOADING});


  const token = getState().auth.auth.token;
  const refresh_token = getState().auth.auth.refresh_token;
  const config = {
    headers: {
      "Content-type": "application/json",
    }
  };

  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  } else {
    let usr = firstLogin();
    if (usr) {
      dispatch(login({username:null, password:null, usr:usr}))
    }
  }


  API.get('api/profile', config)
    .then(res => {
      dispatch({
        type: USER_LOADED,
        payload: res.data
      })
    }).catch(err => {

      if (err.code === 401 && refresh_token) {
        dispatch(refreshToken({refresh_token: refresh_token}))
      } else {
        dispatch(returnErrors(err.message, err.code));
        dispatch({
          type: AUTH_ERROR
        });
      }
    });
};

export const firstLogin = () => {
  let urlParams = new URLSearchParams(window.location.search);

  return urlParams.has('usr')? urlParams.get('usr') : false;
};

export const refreshToken = ({refresh_token}) => dispatch => {
  API.post('/api/token/refresh', {refresh_token: refresh_token})
    .then(res => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      })
    }).then(() => {
      dispatch(loadUser())
    }).catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: AUTH_ERROR
    })
  });
};

export const login = ({ username, password, usr }) => dispatch => {

  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  // Request body
  const body = usr ? {usr} : { username, password };
  let url = usr ? '/api/first-login' : '/api/login_check';

  API.post(url, JSON.stringify(body), config)
    .then(res =>
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      })
    ).then(() => {
    dispatch(loadUser())
  })
    .catch(err => {
      dispatch(
        returnErrors(err.response.data, err.response.status, 'LOGIN_FAIL')
      );
      dispatch({
        type: LOGIN_FAIL
      });
    });
};

export const logout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};
