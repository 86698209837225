import React from 'react';
import ReactDom from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import './main.css';
import App from "./App";
import axios from 'axios';
import {ENTRYPOINT} from "./config/entrypoint";

axios.defaults.baseURL = ENTRYPOINT+"/api/";

ReactDom.render(
    <App />,
    document.getElementById('root')
);

registerServiceWorker();
