import React, { Component, Fragment } from 'react'
import { API_HOST } from "../../config/_entrypoint";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Arrow from '../../UI/Arrow/Arrow';
import { API } from '../../config/Api';
import Video from '../portals/partial/video';

import classes from './homeCategories.module.css';


class HomeCategories extends Component {
    state = {
        contentId: null,
        mouseOverUnit: false,
        previewContent: null,
        myImgContainerWidth: 0,
        myImgContainerHeight: 0
    }

    componentDidMount() {
        this.setCardCoverHeight()
    }
    
    setCardCoverHeight = () => {
        let { clientHeight, clientWidth } = this.refs.myImgContainer;
        const myImgContainerHeight = clientWidth / 1.25
        this.setState({ myImgContainerHeight: myImgContainerHeight })
    }
    

    getPreview = (id) => {
        this.setState({ mouseOverUnit: true }, () => {
            this.setState({ contentId: id })
        })
    }

    discardPreview = () => {
        this.setState({ mouseOverUnit: false })
    }

    render() {
        let settings = {}
        if (this.props.files.length < 6) {
            settings = {
                slidesToShow: this.props.files.length,
                slidesToScroll: this.props.files.length,
                arrows: false,
                infinite: false,
                lazyLoad: "progressive",
                responsive: [
                    {
                        breakpoint: 660,
                        settings: {
                            slidesToShow: this.props.files.length < 2 ? this.props.files.length : 2,
                            slidesToScroll: this.props.files.length < 2 ? this.props.files.length : 2,
                            arrows: false
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: this.props.files.length < 3 ? this.props.files.length : 3,
                            slidesToScroll: this.props.files.length < 3 ? this.props.files.length : 3,
                            arrows: false
                        },
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: this.props.files.length < 3 ? this.props.files.length : 3,
                            slidesToScroll: this.props.files.length < 3 ? this.props.files.length : 3,
                            arrows: true
                        },
                    },
                ]
            }
        } else {
            settings = {
                autoPlaySpeed: 600,
                slidesToShow: 6,
                slidesToScroll: 6,
                arrows: true,
                lazyLoad: "progressive",
                infinite: false,
                prevArrow: <Arrow to="prev" />,
                nextArrow: <Arrow to="next" />,
                responsive: [
                    {
                        breakpoint: 0,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            arrows: false
                        },
                    },
                    {
                        breakpoint: 660,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            arrows: false
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            arrows: false
                        },
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                        },
                    },
                ]
            }
        }
        const link = {
            pathname: "/category/" + this.props.id + "/" + this.props.category,
        }


        return (
            <section >
                <div className="homeCategoriesContainer container">
                    <div className={classes.TitleNavigationWrapper}>
                    <h2 className={classes.SectionTitle}><Link to={link}>{this.props.category}</Link></h2>
                        {(window.innerWidth < 768 && this.props.files.length > 3) || (window.innerWidth < 660 && this.props.files.length > 2) ?
                            <Fragment>
                                <button className="home__nav home__nav--prev" type="button" onClick={() => this.slider.slickPrev()}>
                                    <i className="icon ion-ios-arrow-round-back"></i>
                                </button>
                                <button className="home__nav home__nav--next" type="button" onClick={() => this.slider.slickNext()}>
                                    <i className="icon ion-ios-arrow-round-forward"></i>
                                </button>
                            </Fragment>
                            : null}
                    </div>
                    <div className={classes.Wrapper}>
                        <Slider {...settings} ref={slider => (this.slider = slider)}>
                            {
                                this.props.files.map((file, index) =>
                                    <div className={classes.HomeCard} key={index}>
                                        {
                                            this.state.contentId === file.id && this.state.mouseOverUnit ?
                                                <div className="card__cover" ref="myVideoContainer" style={{ "height": this.state.myImgContainerHeight + 'px' }}>
                                                    <a href={"/content/" + file.id}>
                                                    <Video id={file.id} src={API_HOST + '/api/video/preview/' + file.id} type="video/webm" 
                                                    style={{ "height": this.state.myImgContainerHeight + 'px' }}
                                                    />
                                                    </a>
                                                </div> :
                                                <div className="card__cover"
                                                 onMouseEnter={() => this.getPreview(file.id)}
                                                  onMouseLeave={this.discardPreview}
                                                   ref="myImgContainer"
                                                   style={{ "height": this.state.myImgContainerHeight + 'px' }}
                                                     >
                                                    <img src={API_HOST + "/api/video/image/" + file.id + '/full'} alt="" />
                                                </div>
                                        }
                                        <div className="home-card__content">
                                            <h3 className="card__title"><a href={"/content/" + file.id}>{file.label}</a></h3>
                                            <span className="card__category">
                                                <a href="#">Action</a>
                                                <a href="#">Triler</a>
                                            </span>
                                            <span className="card__rate"><i className="icon ion-ios-star"></i>8.4</span>
                                        </div>
                                    </div>
                                )
                            }
                        </Slider >
                    </div>
                </div>
            </section>
        )
    }
}
export default HomeCategories
