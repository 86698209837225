import React, { Component } from 'react';
class Loader extends Component
{


    constructor(props) {
        super(props);

    }



    render() {
        return (
            <div className={"loader-container dark-bg " + this.props.active}>
                <div className={"loader-element"}> <img src="/img/loader.svg" /></div>
            </div>
        )
    }

}

export default Loader;
