import { ENTRYPOINT } from '../config/entrypoint';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import has from 'lodash/has';
import mapValues from 'lodash/mapValues';
import {API} from "../config/Api";

const MIME_TYPE = 'application/ld+json';

export function fetch(id, options = {}) {
  if ('undefined' === typeof options.headers) options.headers = new Headers();
  if (null === options.headers.get('Accept'))
    options.headers.set('Accept', MIME_TYPE);

  let token = localStorage.getItem('token');
  if (token) {
    options.headers.set('Authorization', 'Bearer ' + token);
  }

  if (
    'undefined' !== options.body &&
    !(options.body instanceof FormData) &&
    null === options.headers.get('Content-Type')
  )
    options.headers.set('Content-Type', MIME_TYPE);

  return global.fetch(new URL(id, ENTRYPOINT), options).then(response => {
    if (response.status === 401) {
      let refreshToken = localStorage.getItem('refresh_token')
      localStorage.removeItem('token')
      if (refreshToken) {
        API.post('/api/token/refresh', {refresh_token: refreshToken}).then(response => {
          return response.data
        }).then(token => {
          if (token) {
            localStorage.setItem('token', token.token);
            localStorage.setItem('refresh_token', token.refresh_token);
            window.location.reload()
          } else {
            localStorage.removeItem('token')
            localStorage.removeItem('refresh_token')
            Promise.reject('token undefined')
          }

          return Promise.resolve();
        }).catch(e => {

        })
      }

    }
    if (response.ok) return response;

    return response.json().then(json => {
      const error = json['hydra:description'] || response.statusText;
      if (!json.violations) throw Error(error);
      let errors = { _error: error };
      json.violations.map(
        violation => (errors[violation.propertyPath] = violation.message)
      );

      throw new SubmissionError(errors);
    });
  });
}

export function mercureSubscribe(url, topics) {
  topics.forEach(topic =>
    url.searchParams.append('topic', new URL(topic, ENTRYPOINT))
  );

  return new EventSource(url.toString());
}

export function normalize(data) {
  if (has(data, 'hydra:member')) {
    // Normalize items in collections
    data['hydra:member'] = data['hydra:member'].map(item => normalize(item));

    return data;
  }
  // Flatten nested documents
  return mapValues(data, value =>
    Array.isArray(value)
      ? value.map(v => { return v })
      : value
  );
}

export function extractHubURL(response) {
  const linkHeader = response.headers.get('Link');
  if (!linkHeader) return null;
  const matches = linkHeader.match(
    /<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/
  );

  return matches && matches[1] ? new URL(matches[1], ENTRYPOINT) : null;
}
