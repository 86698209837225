import React, { Component } from 'react';
import { connect } from "react-redux";
import { list } from "../../actions/portaltag/list";
import { Redirect } from 'react-router';
import { LOCALSTORAGE_KEY } from "../../config/localStorage";
import { getLocalStorageJsonDomain } from '../../utils/domainDispatcher';
import { Header, Footer, Navigation, Section } from '../../components/Layout'
import { Link } from 'react-router-dom';
import Loader from "../Layout/Loader";
import store from '../../store'
import PropTypes from 'prop-types';
import { ENTRYPOINT } from "../../config/entrypoint";
import Breadcrumbs from "./partial/breadcrumbs";
import LoginPage from "../auth/LoginPage";

class Categories extends Component {
    _isMounted = false;
    static propTypes = {
        isAuthenticated: PropTypes.bool
    }
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            portal: getLocalStorageJsonDomain(LOCALSTORAGE_KEY),
            portalTags: []
        }
        this.portalTagListRender = this.portalTagListRender.bind(this)
    }

    async componentDidMount() {
        try {
            if (this.state.portal !== null) {
                let portalTagList = 'api/portal-tags/' + this.state.portal.id + '/category';
                await store.dispatch(list(portalTagList));
                store.subscribe(this.portalTagListRender);
            }
        } catch (err) {
            console.log(err)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
            let portalTagList = 'api/portal-tags/' + this.state.portal.id + '/category';
            store.dispatch(list(portalTagList));
            store.subscribe(this.portalTagListRender);
        }
    }

    render() {
        // if (!this.props.isAuthenticated) {
        //     return <LoginPage />
        // }
        if (this.state.loading ) {
            return <Loader active={"active"} />
        } else {
            if (!this.state.portal) {
                return <Redirect to='/' />
            }
        }
        const portalTagList = this.state.portalTags.map((portalTag, index) =>
            <div className="col-12 col-md-6 col-lg-4" key={index}>

                <div className="price">
                    <div className="price__item price__item--first">
                        <span>{portalTag.name}</span><span></span>
                    </div>

                    <img src={portalTag.image ? ENTRYPOINT + portalTag.image : "img/covers/square-cover.png"} className={"img-fluid"} alt="" />
                    <Link to={"/category/" + portalTag.id + '/' + portalTag.name} className="price__btn">GO TO </Link>
                </div>
            </div>
        )

        return (
            <div>
                <Navigation />
                <section className="section section--first section--bg" data-bg="img/section/section.jpg" style={(this.state.loading ? { opacity: 0 } : { opacity: 1 })}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="section__wrap">

                                    <h1 className="section__title">Categories</h1>
                                    <Breadcrumbs breadcrumbs={[{ url: '/', name: 'Home' }, { name: 'Categories' }]} />

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section" style={(this.state.loading ? { opacity: 0 } : { opacity: 1 })}>
                    <div className="container" style={(this.state.loading ? { opacity: 0 } : { opacity: 1 })}>
                        <div className="row">
                            {portalTagList}

                        </div>
                    </div>
                </section>
                <Footer portal={this.state.portal} hide={(this.state.loading ? "hide" : "")} />
            </div>
        )
    }


    portalTagListRender = () => {
        let portalTags = store.getState().portalTag.list.retrieved;
        if (portalTags !== null) {
            this.setState({
                portalTags: Object.values(portalTags.items),
                loading: false
            }, () => {
            })
        }

    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.auth.isAuthenticated
})

export default connect(mapStateToProps, null)(Categories);

