import * as actionTypes from '../../actions/actionTypes';
import { updatedObject } from '../../utils/updateObject';

const initialState = {
    error: null,
    loading: false,
    retrieved: []
};

const portalFileListStart = (state) => {
    return updatedObject(state, { error: null, loading: true })
};

const portalFileListSuccess = (state, action) => {
    return updatedObject(state, {
        error: false,
        loading: false,
        retrieved: action.retrieved
    });
};

const portalFileListFail = (state, error) => {
    return updatedObject(state, {
        error: error,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.PORTAL_LIST_START: return portalFileListStart(state);
        case actionTypes.PORTAL_LIST_SUCCESS: return portalFileListSuccess(state, action);
        case actionTypes.PORTAL_LIST_FAIL: return portalFileListFail(state, action);
        default:
            return state;
    }
}

export default reducer;