import React, { Component } from 'react';



class Video extends Component {
    state = {
        video: this.refs.vidRef,
        isPlaying: true
    }

    componentDidMount = () => {
        this.refs.vidRef.load()
        this.playVideo();
        this.setState({ video: this.refs.vidRef })
    }
    componentWillUnmount = () => {
        this.pauseVideo()
    }

    playVideo = () => {
        const playPromise  = this.refs.vidRef.play();
        if(playPromise !== undefined){
            playPromise
                .then(_ => {
                    
                })
                .catch(error => {
                    
                })
        }
    }

    onPlaying = () => {
        this.setState({ isPlaying: true })
    }

    onPause = () => {
        this.setState({ isPlaying: false })
    }

    pauseVideo = () => {
        const pausePromise  = this.refs.vidRef.pause();
        if(pausePromise !== undefined){
            pausePromise
                .then(_ => {
                
                })
                .catch(error => {
                
                })
        }
    }

    render = () => {
        return (           
                <video
                onMouseEnter={this.playVideo} 
                onMouseLeave={this.pauseVideo}
                onPlaying={this.onPlaying}
                onPause={this.onPause}
                ref='vidRef'
                src={this.props.src}
                type={this.props.type}
                muted="muted"
                className="HomePageVideo"
                />
        )
    }
}

export default Video;
