import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router';

import { Footer, Navigation, Header } from '../../components/Layout';
import Loader from "../Layout/Loader";
import { getLocalStorageJsonDomain, matchDomain } from "../../utils/domainDispatcher";
import { LOCALSTORAGE_KEY } from "../../config/localStorage";
import Carousel from "./partial/carousel";
import HomeCategories from '../homeCategories/homeCategories';
import { API } from "../../config/Api";
import store from '../../store';
import { list } from "../../actions/portaltag/list";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portal: getLocalStorageJsonDomain(LOCALSTORAGE_KEY),
      notFound: false,
      loading: true,
      homeCategories: []
    }
  }

  componentDidMount() {
    matchDomain('api/portal', LOCALSTORAGE_KEY).then(element => {
      if (typeof element === 'object' && typeof element.name !== 'undefined') {
        this.setState({
          portal: element,
          loading: false,
          notFound: false
        }, () => {
          API.get('api/portal/' + this.state.portal.id + '/get-home-categories')
            .then(result => {
              this.setState({
                homeCategories: result.data, 
              }, () => {
                let portalTagList = 'api/portal-tags/' + this.state.portal.id + '/category';
                store.dispatch(list(portalTagList));
              })
            }).catch(err => {
              console.log(err)
            })
        })

      } else {
        this.setState({
          notFound: true,
          loading: false
        })
      }
    })
  }



  render() {
    if (this.state.loading) {
      return <Loader />
    }
    if (this.state.notFound) {
      return <Redirect to='/not-found' />
    }

    let homeCategories = this.state.homeCategories.map((category, index) =>
      <div key={index}>
        <HomeCategories files={category.files} category={category.category.name} id={category.category.id}/>
      </div>
    )
    return (
      <div>
        <Navigation />
        <section className="home">
          <Carousel url={'/api/portal/' + this.state.portal.id + '/get-new-items'} />
        </section>
        <section className="content">
          <div className="content__head">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2 className="content__title">Recommended</h2>
                </div>
              </div>
            </div>
          </div>
          {homeCategories}
        </section>
        <Footer portal={this.state.portal} />
      </div>
    )
  }
}



export default withRouter(Home);

