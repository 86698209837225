import React, { Component } from 'react';
import { Link } from 'react-router-dom'


class Breadcrumbs extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      links : null
    };
  }

  render() {
    let breadcrumbs = this.props.breadcrumbs.map((link, index) => {

      if (link.url) {
        return <li key={index} className={"breadcrumb__item " + (index+1 === this.props.breadcrumbs.length ? "breadcrumb__item--active" : "")}><Link to={link.url}>{link.name}</Link></li>
      } else {
        return <li key={index} className="breadcrumb__item breadcrumb__item--active">{link.name}</li>
      }
    }

    );
    return(
      <ul className="breadcrumb">
        {breadcrumbs}
      </ul>
    )
  }
}

export default Breadcrumbs
