import axios from 'axios';
import * as actionTypes from '../actionTypes';

export const portalFileListStart = () => {
    return {
        type: actionTypes.PORTAL_LIST_START
    };
};

export const portalFileListSuccess = (retrieved) => {
    return {
        type: actionTypes.PORTAL_LIST_SUCCESS,
        retrieved: retrieved
    };
};

export const portalFileListFail = (error) => {
    return {
        type: actionTypes.PORTAL_LIST_FAIL,
        error: error
    };
};


export const units = (id, name, limit, offset) => {
    return dispatch => {
        dispatch(portalFileListStart())
        const data = {
            limit: limit,
            offset: offset
        }
        axios('portal-files' + '/' + id + '/' + name + '/' , {
            method: 'GET',
            params: data
        })
            .then(responce => {
                dispatch(portalFileListSuccess(responce.data))
            })
            .catch(error => {
                dispatch(portalFileListFail(error))
            });
    };
};