import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import auth from './auth';
import portalTag from './portaltag';
import portalFile from './portalfile';
import portal from './portal';
import tag from './tag';
import file from './file';
import portalLogo from './portallogo';



export default combineReducers({
  routing, auth, portalTag, portalFile, portal, tag, file, portalLogo
})
